import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import DataTableV2, { TableColumn } from '@/components/datatable/DataTable';
import { CountryReport } from '@/core';
import Filter from '@/components/filters/Filter';
import { useGetCountryReport } from '@/core/hooks/stats';
import { Link } from 'react-router-dom';
import { countries } from '@/utils/constants';

export default function CountryReports() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageTitle('Country Reports'));
    }, [dispatch]);

    const [filters, setFilters] = useState({
        country: '',
        fromDate: '',
        endDate: '',
    });

    const { loading, countryReport, fetchCountryReport } =
        useGetCountryReport();
        useEffect(() => {
            fetchCountryReport(filters.country, filters.fromDate, filters.endDate);
         
        }, [])
        

    const handleFilterChange = (name: string, value: string) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const handleApplyFilters = () => {
        const today = new Date().toISOString().split('T')[0];
        const dateToUse = filters.endDate || today;
        const { country, fromDate, endDate } = filters;
        fetchCountryReport(country, fromDate, dateToUse);
    };

    const resetFilters = () => {
        setFilters({
            country: '',
            fromDate: '',
            endDate: '',
        });
    };

    const columns: TableColumn<CountryReport>[] = [
        {
            title: 'Country Name',
            accessor: 'countryCode',
            render: row => (
                <p className="capitalize">{row.residence_country || ''}</p>
            ),
        },
        {
            title: 'Amount',
            accessor: 'amount',
            render: row => {
                if (Array.isArray(row.amount) && row.amount.length > 0) {
                    return row.amount.map(currencyObject => {
                        const currency = Object.keys(currencyObject)[0];
                        const value = currencyObject[currency];
                        return (
                            <p key={currency}>
                                {currency}: {value}
                            </p>
                        );
                    });
                } else if (row.amount && typeof row.amount === 'object') {
                    return Object.keys(row.amount).map(currency => (
                        <p key={currency}>
                            {currency}: {row.amount[currency]}
                        </p>
                    ));
                } else {
                    return null;
                }
            },
        },
        {
            title: 'Total contributors',
            accessor: 'count',
            render: row => <p>{row.count || ''}</p>,
        },
    ];

    return (
        <div>
            <ol className="flex text-primary font-semibold dark:text-white-dark mb-2">
                <Link
                    to="/account/admin"
                    className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]"
                >
                    <button className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                        Home
                    </button>
                </Link>

                <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                    <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                        Country Reports
                    </button>
                </li>
            </ol>

            <DataTableV2
                columns={columns}
                data={countryReport ?? []}
                total={countryReport?.length ?? 0}
                tableName="Country Reports"
                isLoading={loading}
                filterComponent={
                    <Filter
                        title="Filter Country Reports"
                        resetFilters={resetFilters}
                        onApplyFilters={handleApplyFilters} // Add onApply to trigger the filter
                    >
                        <div className="flex flex-col">
                            <div className="mb-4">
                                <label htmlFor="countryCode">
                                    Country name:
                                </label>
                                <select
                                    id="countryCode"
                                    value={filters.country}
                                    onChange={e =>
                                        handleFilterChange(
                                            'country',
                                            e.target.value
                                        )
                                    }
                                    className="form-select"
                                >
                                    <option value="" disabled>
                                        Select a country
                                    </option>
                                    {countries.map((country, index) => (
                                        <option
                                            key={index}
                                            value={country.name.toLowerCase()}
                                        >
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="fromDate">From Date:</label>
                                <input
                                    type="date"
                                    id="fromDate"
                                    value={filters.fromDate}
                                    onChange={e =>
                                        handleFilterChange(
                                            'fromDate',
                                            e.target.value
                                        )
                                    }
                                    className="form-input"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="endDate">End Date:</label>
                                <input
                                    type="date"
                                    id="endDate"
                                    value={filters.endDate}
                                    onChange={e =>
                                        handleFilterChange(
                                            'endDate',
                                            e.target.value
                                        )
                                    }
                                    className="form-input"
                                />
                            </div>
                        </div>
                    </Filter>
                }
                currentPage={1}
                nextPage={0}
                previousPage={0}
                lastPage={1}
            />
        </div>
    );
}
