import React from 'react';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    background?: string;
    foreground?: string;
}

export default function Button(props: ButtonProps) {
    const { children, background, foreground, ...rest } = props;
    return (
        <button {...rest} className={` btn btn-primary `}>
            {children}
        </button>
    );
}
