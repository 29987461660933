import { ReactNode } from 'react';
import clsx from 'clsx';
import { FieldError } from 'react-hook-form';

type FieldWrapperProps = {
    children: ReactNode;
    label?: string;
    icon?: ReactNode;
    className?: string;
    error?: FieldError | any;
    isLoading?: boolean;
};

export type FieldWrapperPassThroughProps = Omit<
    FieldWrapperProps,
    'className' | 'children' 
>;

export const FieldWrapper = (props: FieldWrapperProps) => {
    const {
        children,
        label,
        icon,
        error,
        className = '',
        isLoading = false,
    } = props;

    return (
        <div className="block w-full">
            <label>
                {label && (
                    <div className="mb-1 dark:text-white-light">
                        {label}
                    </div>
                )}

                <div
                    className={clsx(
                        'flex items-center rounded overflow-hidden bg-transparent text-[15px] font-medium',
                        isLoading ? 'cursor-wait opacity-80' : '',
                        error?.message ? 'border-red-600' : '',
                        className
                    )}
                >
                    {icon && <div className="px-3 text-primary">{icon}</div>}

                    <div className="grow">{children}</div>
                </div>
            </label>

            {error?.message && (
                <div
                    role="alert"
                    aria-label={error.message}
                    className={`${icon ? 'ml-12' : ''} mt-2 text-danger`}
                >
                    {error.message}
                </div>
            )}
        </div>
    );
};
