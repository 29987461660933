import { UseFormRegisterReturn } from 'react-hook-form';
import clsx from 'clsx';
import { ChangeEvent } from 'react';
import { FieldWrapper, FieldWrapperPassThroughProps } from '../fieldwrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
	placeholder: string;
	className?: string;
    defaultValue?:any;
	registration?: Partial<UseFormRegisterReturn>;
    options: {
        text: string;
        value: any;
    }[],
    onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
};

export default function SelectField(props: InputFieldProps) {
    const {
        label,
        icon,
        placeholder = '',
        error,
        defaultValue='',
        registration,
        isLoading,
        className = '',
        onChange,
        options
    } = props;

    return (
        <FieldWrapper label={label} icon={icon} error={error} isLoading={isLoading}>
            <div className="flex w-full flex-row items-center justify-center">
                <select
                    autoComplete="off"
                    disabled={isLoading}
                    onChange={onChange}
                    defaultValue={defaultValue
                    }
                    className={clsx(
                        'form-select',
                        icon ? 'px-0' : 'pl-3',
                        className
                    )}
                    
                    {...registration}
                >
                    <option value="" className="text-gray-800 ">{placeholder}</option>
                    {options.map((item:any, index:any) => <option key={index} value={item.value} className='capitalize'>{item.text}</option> )}
                </select>
            </div>
        </FieldWrapper>
    );
}
