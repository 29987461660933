import { UseFormRegisterReturn } from 'react-hook-form';

import { ChangeEvent, useState } from 'react';
import clsx from 'clsx';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { FieldWrapper, FieldWrapperPassThroughProps } from '../fieldwrapper';
import { DefaultValue } from '@mantine/core/lib/MultiSelect/DefaultValue/DefaultValue';

type InputFieldProps = FieldWrapperPassThroughProps & {
	type?: 'text' | 'number' | 'email' | 'password' | 'date' | 'file' | 'tel';
	placeholder?: string;
	className?: string;
    defaultValue?:string;
	registration?: Partial<UseFormRegisterReturn>;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export default function   InputField(props: InputFieldProps) {
    const {
        type = 'text',
        label,
    
        defaultValue='',
        icon,
        placeholder = '',
        error,
        registration,
        isLoading,
        className = '',
        onChange
    } = props;

    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

    return (
        <FieldWrapper label={label} icon={icon} error={error} isLoading={isLoading}>
            <div className="flex w-full flex-row items-center justify-center">
                <input
                    type={
                        type === 'password' ? (passwordVisible ? 'text' : 'password') : type
                    }
                    autoComplete="off"
                    placeholder={placeholder}
                    disabled={isLoading}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    className={clsx(
                        'form-input',
                        icon ? 'px-2' : 'pl-3',
                        type === 'date' && 'uppercase',
                        className
                    )}
                    {...registration}
                />
                {type === 'password' &&
                    (passwordVisible ? (
                        <EyeIcon
                            className="icon-md mx-2 cursor-pointer w-5 text-primary"
                            onClick={() => setPasswordVisible(false)}
                        />
                    ) : (
                        <EyeSlashIcon
                            className="icon-md mx-2 cursor-pointer w-5 text-primary"
                            onClick={() => setPasswordVisible(true)}
                        />
                    ))}

            </div>
        </FieldWrapper>
    );
}
